
import router from '@/router'
import { defineComponent, ref, onMounted, } from 'vue';
import { CaretLeftOutlined, CaretRightOutlined, } from '@ant-design/icons-vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja';
import 'dayjs/plugin/updateLocale';
import { holidays } from '@holiday-jp/holiday_jp';
import axios from 'axios';
import { useCookies } from 'vue3-cookies';
import Constains from '@/components/Constains';
import { notification } from 'ant-design-vue';

export default defineComponent({
  components: {
    CaretLeftOutlined,
    CaretRightOutlined,
    datepicker: VueDatePicker,
  },

  setup() {
    const cookies  = useCookies();

    const customCellEvent = (record:any,rowIndex:any,column:any) => {
      return {
        onClick: () => {
          if(column.tabletype == 'monthly'){
            const dayStr = column.dataIndex
            const date = record[dayStr]
            _scheduleModanView(loginUserID.value,date,false)
          } else if(column.tabletype == 'staff'){
            const staffCode = column.code
            const date = record.date
            _scheduleModanView(staffCode,date,true)
          } else if(column.tabletype == 'weekly'){
            const staffCode = record.staff.code
            const date = column.date
            _scheduleModanView(staffCode,date,true)
          }
        },
      }
    }

    const loginUserID = ref("")
    const loginUserAuthority = ref(true)
    const loginUserBushoID = ref(0)
    
    const isPC = ref(false)
    const staffViewMode = ref(false)

    const monthlyColumns:any = [ 
      {title:'月',dataIndex:'mon', width: 'calc(100vw / 7)', tabletype:'monthly', customCell:customCellEvent, },
      {title:'火',dataIndex:'tue', width: 'calc(100vw / 7)', tabletype:'monthly', customCell:customCellEvent, },
      {title:'水',dataIndex:'wed', width: 'calc(100vw / 7)', tabletype:'monthly', customCell:customCellEvent, },
      {title:'木',dataIndex:'thu', width: 'calc(100vw / 7)', tabletype:'monthly', customCell:customCellEvent, },
      {title:'金',dataIndex:'fri', width: 'calc(100vw / 7)', tabletype:'monthly', customCell:customCellEvent, },
      {title:'土',dataIndex:'sat', width: 'calc(100vw / 7)', tabletype:'monthly', customCell:customCellEvent, },
      {title:'日',dataIndex:'sun', width: 'calc(100vw / 7)', tabletype:'monthly', customCell:customCellEvent, } ]
    const weeklyColumns:any = ref([])
    const staffColumns:any = ref([])
    const monthlyDataSource:any = ref([])
    const weeklyDataSource:any = ref([])
    const staffDataSource:any = ref([])
    //const schedules = ref<any[]>([]);

    let weeklyBaseMonday:any;
    const isThisWeek = ref(true)
    const isThisMonth = ref(true)
    const selectWeek:any = ref<Dayjs>(dayjs())
    const selectMonth:any = ref<Dayjs>(dayjs())
    const selectWeekList:any = ref([])
    const selectMonthValue:any = ref()

    const day = new String('月火水木金土日');
    const projects = ref<any[]>([]);
    const allProject = ref<any[]>([]);
    const workCodes = ref<any[]>([]);
    
    const modalVisible = ref(false)
    const holidayVisible = ref(false)
    const modalDate:any = ref()
    const modalStaff:any = ref()
    const modalTitle:any = ref()
    const modalEditable = ref(false)
    const modalAchieves:any = ref([])
    const options = ref<any[]>([]);
    const AllCodes = ref<any[]>([]);
    const loading = ref(false)
    const AchieveInputVisible = ref(false)
    const AchieveInputData:any = ref()
    const AchieveInputLoading = ref(false)
    const BedoreDate = ref()
    const AfterDate = ref()

    const bushofilter:any = ref([])
    const bushoSource:any = ref([])

    const staffColumnsBushoFilter:any = ref([])
    const weeklyDataSourceBushoFilter:any = ref([])

    const restmode:any = ref(null)

    const registSchedule:any = ref({
      staff:null,
      date:null,
      project:null,
      action:null,
      code:null,
      start:null,
      end:null,
      achieve:0,
    })

    const registAchieve:any = ref({
      id:0,
      staff:null,
      date:null,
      project:null,
      action:null,
      code:null,
      plan:null,
      achieve:null,
      number:null,
      overtime:null,
      addChange:null,
    })

    onMounted(async() => {
      var userAgent = window.navigator.userAgent.toLowerCase();
      if (
        userAgent.indexOf("iphone") != -1 ||
        userAgent.indexOf("ipad") != -1 ||
        userAgent.indexOf("android") != -1 ||
        userAgent.indexOf("mobile") != -1
      ) {
        isPC.value = false
      } else {
        isPC.value = true
      }

      loading.value = true
      //今日の日付を取得
      const now = new Date()
      selectWeek.value = dayjs()
      selectWeek.value = selectWeek.value.subtract(selectWeek.value.day() - 1,'days')
      selectWeekList.value.push(selectWeek.value.toDate())
      selectWeekList.value.push(selectWeek.value.add(1,'w').subtract(1,'day').toDate())
      selectMonth.value = dayjs()
      selectMonthValue.value = { month:selectMonth.value.month(),year:selectMonth.value.year() }

      //月曜日の日付を取得
      const this_monday  = now.getDate() - now.getDay() + 1
      weeklyBaseMonday = new Date();
      weeklyBaseMonday.setDate(this_monday)

      //マンスリーカレンダーに日付を入力
      monthPickerChange(dayjs())

      weeklyColumns.value.push({
          title:'',
          dataIndex:'staff',
          width: '150px',
          fixed:'left',
        })
      
      for(let i = this_monday; i <= this_monday + 6; i++){
        const pushDate = new Date()
        pushDate.setDate(i)
        pushDate.setHours(0);pushDate.setMinutes(0);pushDate.setSeconds(0); //時刻を00:00:00に合わせる
        weeklyColumns.value.push({
          title:pushDate.getDate()+'日('+day.charAt(i - this_monday)+')',
          date:pushDate,
          day: i - this_monday,
          dataIndex:'schedules',
          width: weeklyColumnsWidth,//'calc(100vw / 8)',//'100px',//'calc(100vw / 8)',
          tabletype:'weekly', 
          customCell:customCellEvent, 
        })
      }

     await _reload()
    })

    const weeklyColumnsWidth = '200px' //'calc(100vw / 8)'

    const _reload = async () => {
     // schedules.value = []

      workCodes.value = [];
      AllCodes.value = [];
      projects.value = [];
      allProject.value = []
      options.value = [];
      bushoSource.value = [];
      var token = cookies.cookies.get('token');
        if(token == null){
          //ログインページに飛ばす
          if(Constains.autoLogout){
            router.push('/login');
          return;
          }
        }
      //ログインユーザー情報を取得
        try{
          let res = await axios.get(Constains.tracerbaseURL+'/api/Login/'+token,{headers:{
            'Authorization' :'Bearer '+token, }});
        if(res.status == 200){
          let result = res.data;
          if(result.activate == 0) {
            
            if(Constains.autoLogout){
              notification.error({
                message: '非アクティブなアカウントです.',
              })
              setTimeout((()=>{
                router.push('login')
                return;
              }),3000)
            }
          }
            loginUserID.value = result.id.toString()
            loginUserAuthority.value = result.authority == 1
            loginUserBushoID.value = result.busho
         } else if(res.status==400) {
          //ログアウトしてログイン画面に
          if(Constains.autoLogout){
            cookies.cookies.remove('token');
              notification.error({
                message: '無効なアカウントです.',
              })
              setTimeout((()=>{
                router.push('login')
                return;
              }),3000)
            }
         }
        } catch (e) {
          notification.error({
                message: 'ログインに失敗しました.',
              })
              return
        }

        //開発計画を取得
        let res = await axios.get(Constains.tracerbaseURL+'/api/Projects',{headers:{
          'Authorization' :'Bearer '+token,
        }});

        if(res.status == 200){
          let results = res.data.reverse();
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            if(result["isfin"] == 0){
              projects.value.push({
                code:result['id'],
                name:result['name'],
                abbname:result['abbreviation'],
                start:result['start_date'],
                end:result['end_date'],
                disabled:result["isfin"] == 1,
                value:result['id'],
                label:result['name'],
              });
            }
            allProject.value.push({
                code:result['id'],
                name:result['name'],
                abbname:result['abbreviation'],
                start:result['start_date'],
                end:result['end_date'],
                disabled:result["isfin"] == 1,
                value:result['id'],
                label:result['name'],
              })
          }
        } else {
          notification.error({
                message: '開発計画の取得に失敗しました.',
          })
          
        }

        //スケジュールを取得
        try{
          await weeklyReload()
        } catch (e){
          notification.error({
                message: '週データの取得に失敗しました.'+e,
          })
        }

        try{
          await monthlyReload()
        } catch (e){
          notification.error({
                message: '月データの取得に失敗しました.'+e,
          })
        }
        
        

        //作業コードを取得
        res = await axios.get(Constains.tracerbaseURL+'/api/Codes',{headers:{
          'Authorization' :'Bearer '+token,
        }});

        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];

            AllCodes.value.push({
              id:result['id'],
              value:result['code'],
              code:result['code'],
              busho:result['busho'],
              label:result['code']+':'+result['label'],
              disabled: !result['code'].includes('-'),
             });
          }

         try{
          AllCodes.value.sort((a,b)=>{
            const a_bunrui = a.code.includes('-') ?  a.code.substring(0,a.code.indexOf('-')) : a.code
            const b_bunrui = b.code.includes('-') ?  b.code.substring(0,b.code.indexOf('-')) : b.code
            if(a_bunrui!=b_bunrui) {
              if(isNaN(Number(a_bunrui)) && isNaN(Number(b_bunrui))) return a_bunrui < b_bunrui ? -1 :1
              if(isNaN(Number(a_bunrui))) return 1
              if(isNaN(Number(b_bunrui))) return -1
              return parseInt(a_bunrui) - parseInt(b_bunrui)
            } 
            if(!a.code.includes('-')) return -1
            if(!b.code.includes('-')) return 1
            const a_kubun = a.code.substring(a.code.indexOf('-')+1,a.code.length)
            const b_kubun = b.code.substring(b.code.indexOf('-')+1,b.code.length)
            return parseInt(a_kubun) - parseInt(b_kubun)
          })

          //大分類を登録
          AllCodes.value.forEach(code => {
            if(code.busho.length == 0 || loginUserAuthority.value || code.busho.includes(loginUserBushoID.value)){
              if(!code.code.includes('-')){
                workCodes.value.push({
                  label:code.label,
                  code:code.code,
                  options:[],
                })
              }
            }
          });
          //小分類を登録
          AllCodes.value.forEach(code => {
            if(code.busho.length == 0 || loginUserAuthority.value || code.busho.includes(loginUserBushoID.value)){
              if(code.code.includes('-')){
                const daibunrui = code.code.substring(0,code.code.indexOf('-'))
                const tmp = workCodes.value.filter(e=>e.code===daibunrui)
                if(tmp.length > 0){
                  tmp[0].options.push({
                    id:code.id,
                    value:code.code,
                    code:code.code,
                    label:code.label,
                  })
                } else {
                  workCodes.value.push({
                    id:code.id,
                    value:code.code,
                    code:code.code,
                    label:code.label,
                   })
                }
              }
            }
          });
         } catch (e) {
          notification.error({
                message: '作業コードのソート処理に失敗しました.'+e,
          })
         }
        } else {
          notification.error({
                message: '作業コードの取得に失敗しました.',
          })
          
        }

        //部署を取得
        res = await axios.get(Constains.tracerbaseURL+'/api/Busho',{headers:{
          'Authorization' :'Bearer '+token,
        }});

        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            bushoSource.value.push({
              value:result['id'],
              label:result['label'],
              edittype:result['edittype'],
            });
          }
        } else {
          notification.error({
                message: '部署の取得に失敗しました.',
          })
          
        }

        try{
          await _getStaffList()
        } catch(e){
          notification.error({
                message: 'スタッフの取得に失敗しました.',
          })
        }
        

        loading.value = false
        var isLogin = cookies.cookies.get('login')

        if(isLogin !== null) {
          cookies.cookies.remove('login')
          let basedayjs = dayjs()
          /*
          notification.open({
            message: 'test:'+loginUserID.value+','+basedayjs.toDate()+','+staffColumns.value.length,
            placement:'bottomRight',
            });
          */
          _scheduleModanView(loginUserID.value,basedayjs.toDate(),true)
          inputScheduleVisible(null)
        }        
    }

    const weeklySchedules:any = ref([])

    const weeklyReload = async()=>{
      weeklySchedules.value = []
      loading.value = true
      const start = selectWeekList.value[0].toLocaleDateString('ja-JP')
      const end = selectWeekList.value[1].toLocaleDateString('ja-JP')

      var token = cookies.cookies.get('token');
      //スケジュールを取得
      const res = await axios.get(Constains.tracerbaseURL+'/api/Schedules?start='+start+'&end='+end+'&staffs='+staffViewMode.value.toString(),{headers:{
        'Authorization' :'Bearer '+token,
      }});
      //console.log('week',res,JSON.stringify(res).length,encodeURIComponent(JSON.stringify(res)).replace(/%../g,"x").length)
      if(res.status == 200){
        let results = res.data;
        mySchedule.length = 0
        staffSchedule.length = 0
        for(let i = 0; i < results.length; i++){
          let result = results[i];
          if(loginUserID.value == result['staff']){
            mySchedule.push({
              id:result['id'],
              project:result['project'],
              action:result['action'],
              code:result['code'],
              staff:result['staff'].toString(),
              date:result['date'],
              plan:result['plan'],
              achieve:result['achieve'],
              number:result['number'],
              overtime:result['overtime'],
              addChange:result['addChange'],
            })
          }

          staffSchedule.push({
            id:result['id'],
            project:result['project'],
            action:result['action'],
            code:result['code'],
            staff:result['staff'].toString(),
            date:result['date'],
            plan:result['plan'],
            achieve:result['achieve'],
            number:result['number'],
            overtime:result['overtime'],
            addChange:result['addChange'],
          })

          /*
          
          weeklySchedules.value.push({
            id:result['id'],
            project:result['project'],
            action:result['action'],
            code:result['code'],
            staff:result['staff'].toString(),
            date:result['date'],
            plan:result['plan'],
            achieve:result['achieve'],
            number:result['number'],
            overtime:result['overtime'],
            addChange:result['addChange'],
          });*/
        }
        if(staffViewMode.value) weeklySchedules.value = staffSchedule
        else weeklySchedules.value = mySchedule

      } else {
        notification.error({
          message:'週のスケジュールの取得に失敗しました'
        })
      }
    }

    const monthlySchedules:any = ref([])
    const monthlyReload = async()=>{
      monthlySchedules.value = []
      loading.value = true
      const start = monthlyDataSource.value[0].mon.toLocaleDateString('ja-JP')
      const end = monthlyDataSource.value[monthlyDataSource.value.length - 1].sun.toLocaleDateString('ja-JP')

      var token = cookies.cookies.get('token');
      //スケジュールを取得
      const res = await axios.get(Constains.tracerbaseURL+'/api/Schedules?start='+start+'&end='+end+'&staffs=false',{headers:{
        'Authorization' :'Bearer '+token,
      }});
      //console.log('month',res,JSON.stringify(res).length,encodeURIComponent(JSON.stringify(res)).replace(/%../g,"x").length)
      if(res.status == 200){
        let results = res.data;
        for(let i = 0; i < results.length; i++){
          let result = results[i];
          monthlySchedules.value.push({
            id:result['id'],
            project:result['project'],
            action:result['action'],
            code:result['code'],
            staff:result['staff'].toString(),
            date:result['date'],
            plan:result['plan'],
            achieve:result['achieve'],
            number:result['number'],
            overtime:result['overtime'],
            addChange:result['addChange'],
          });
        }
      } else {
        notification.error({
          message:'月のスケジュールの取得に失敗しました'
        })
      }
    }

    const getEditType = (staffID:number) => {
      var tmp = staffColumns.value.filter(e=>e.code==staffID)
      if(tmp.length == 0) return 0
      var staff = tmp[0]
      tmp = bushoSource.value.filter(e=>e.value==staff.busho)
      if(tmp.length == 0) return 0
      if(tmp[0].edittype == undefined) return 0
      return tmp[0].edittype
    }

    const _isScheduleModalWeekly = ref(true)

    const _scheduleModanView = (staffID:any,date:Date,isweek:boolean) =>{
      
          modalStaff.value = staffID;
          modalDate.value = date.toLocaleDateString('ja-JP')
          _isScheduleModalWeekly.value = isweek
          const day = dayjs(date)
          const days = new String('日月火水木金土');
          
          modalTitle.value = date.toLocaleDateString('ja-JP')+'('+days.charAt(day.day())+')'+' ' + getStaffName(staffID) 
          
          const tmp = isweek ? weeklySchedules.value.filter(e => e.date == day.format('YYYY/M/D')).filter(e => e.staff == staffID) : monthlySchedules.value.filter(e => e.date == day.format('YYYY/M/D')).filter(e => e.staff == staffID)
          modalAchieves.value = tmp
          registSchedule.value.staff = staffID
          registSchedule.value.date = date
          registAchieve.value.staff = staffID
          registAchieve.value.date = modalDate.value
          BedoreDate.value = day.subtract(1,'days').format('M/D')
          AfterDate.value = day.add(1,'days').format('M/D')
          staffColumns.value[0].fixed=null
          weeklyColumns.value[0].fixed=null

          fullVisible.value = true

    }

    const getSumAchieve = () => {
      let sum = 0
      modalAchieves.value.forEach(achieve => {
        sum += achieve.achieve
      });
      return sum
    }
    
    const getSumOvertime = () => {
      let sum = 0
      const staffedittype = getEditType(modalStaff.value)
      console.log(staffedittype,modalAchieves.value)

      modalAchieves.value.forEach(achieve => {
        if(staffedittype == 0){
          if(achieve.overtime) sum += achieve.overtime
        } else {
          sum += achieve.achieve
        }
      });

      
      if(staffedittype != 0) {
        if(sum > 8){
          sum -= 8
        } else {
          sum = 0
        }
          
      }

      return sum
    }

    const _getStaffList = async() => {
      //スタッフ一覧を取得
      var token = cookies.cookies.get('token');
      const res = await axios.get(Constains.tracerbaseURL+'/api/Staffs',{headers:{
          'Authorization' :'Bearer '+token,
        }});
      
      var list:any[] = []
      if(res.status == 200){
        let results = res.data;
        results.sort((a,b)=>{
          return a.id - b.id 
        })
        results.sort((a,b)=>{
          if(a.sort == 0 && b.sort == 0) return 0
          if(a.sort == 0) return 1
          if(b.sort == 0) return -1
          return a.sort - b.sort
        })
        /*
        dataSource.value.sort((a,b)=>{
              return a.id - b.id 
            })

            dataSource.value.sort((a,b)=>{
              if(a.sort == null && b.sort == null) return 0
              if(a.sort == null) return 1
              if(b.sort == null) return -1
              return a.sort - b.sort
            })
        */

        for(let i = 0; i < results.length; i++){
          let result = results[i];
          if(result.calendar == 1){
            if(result['id']==loginUserID.value){
              list.unshift({
                code:result['id'].toString(),
                title:result['name'],
                dataIndex:'staff',
                tabletype:'staff',
                width:100,
                busho:result['busho'],
              });
            } else {
                list.push({
                code:result['id'].toString(),
                title:result['name'],
                dataIndex:'staff',
                tabletype:'staff',
                width:100,
                busho:result['busho'],
              });
            }
          }
        }
      } else {
        //tokenが無効化されているのでログインページに飛ばす
        router.push('/login');
        return;
      }

      staffColumns.value = JSON.parse(JSON.stringify(list))
      for(let i = 0; i < staffColumns.value.length; i++) staffColumns.value[i].customCell = customCellEvent
      staffColumns.value.unshift({ title:'日付', dataIndex:'date',width:60, fixed: 'left',maxWidth:60 })
      staffColumnsBushoFilter.value = []
      if(staffViewMode.value){
        staffColumnsBushoFilter.value.push(...staffColumns.value)
      } else {
        staffColumnsBushoFilter.value.push(staffColumns.value[0])
        staffColumnsBushoFilter.value.push(staffColumns.value[1])
      }
      

      //weeklyDataSourceに入力
      weeklyDataSource.value = []
      weeklyDataSourceBushoFilter.value = []
      
      for( let i = 0; i < list.length; i++){
        weeklyDataSource.value.push({
          staff: list[i],
          schedules: weeklySchedules,
        })
        if(staffViewMode.value || i == 0){
          weeklyDataSourceBushoFilter.value.push({
          staff: list[i],
          schedules: weeklySchedules,
        })
        }
      }

      //staffDataSourceに入力
      staffDataSource.value = []
      for( let i = 0; i < 7; i++){
        staffDataSource.value.push({
          date: weeklyColumns.value[ i + 1 ].date,
          day: day[i],
          schedules: weeklySchedules,
        })
      }
    }

    const beforeWeek = async() =>{
      selectWeek.value = selectWeek.value.subtract(1,'w')
      datePickerChange(selectWeek.value)
      await weeklyReload()
      loading.value = false
    }

    const thisWeek = async() =>{
      selectWeek.value = dayjs()
      selectWeek.value = selectWeek.value.subtract(selectWeek.value.day() - 1,'days')
      datePickerChange(selectWeek.value)
      await weeklyReload()
      loading.value = false
    }

    const afterWeek = async() =>{
       selectWeek.value = selectWeek.value.add(1,'w')
       datePickerChange(selectWeek.value)
       await weeklyReload()
       loading.value = false
    }

    const weekUpdate = async(date:any) => {
      selectWeek.value = dayjs(date[0])
      datePickerChange(selectWeek.value)
      await weeklyReload()
      loading.value = false
    }

    const weekFormat = (dates:Date[]) => {
      const month = dates[0].getMonth() + 1
      return dates[0].getFullYear() + '年' + month + '月 第' + getWeekNumber()+'週'
    }

    const datePickerChange = (dayjs:any) => {
      const selectDate = dayjs.toDate()

      //月曜日の日付を取得
      const this_monday  = selectDate.getDate() - selectDate.getDay() + 1
      weeklyBaseMonday = dayjs.toDate()
      weeklyBaseMonday.setDate(this_monday)

      weeklyColumns.value = []
      weeklyColumns.value.push({
          title:'',
          dataIndex:'staff',
          width: '150px',
          fixed:'left',
        })
      for(let i = this_monday; i <= this_monday + 6; i++){
        const pushDate = dayjs.toDate()
        pushDate.setDate(i)
        weeklyColumns.value.push({
          title:pushDate.getDate()+'日('+day.charAt(i - this_monday)+')',
          date:pushDate,
          dataIndex:'schedules',
          width: weeklyColumnsWidth,
          tabletype:'weekly', 
          customCell:customCellEvent, 
        })
        staffDataSource.value[ i - this_monday].date = pushDate
      }

      const now = new Date()
      const now_monday  = now.getDate() - now.getDay() + 1
      now.setDate(now_monday)
      isThisWeek.value = weeklyBaseMonday.toLocaleDateString('ja-JP') === now.toLocaleDateString('ja-JP');
      selectWeekList.value[0] =selectWeek.value.toDate()
      selectWeekList.value[1] = selectWeek.value.add(1,'w').subtract(1,'day').toDate()
    }

    const beforeMonth = async() => {
      selectMonth.value = selectMonth.value.subtract(1,'month')
      selectMonthValue.value = { month:selectMonth.value.month(),year:selectMonth.value.year() }
      monthPickerChange(selectMonth.value)
      await monthlyReload()
      loading.value = false
    }

    const afterMonth = async() => {
      selectMonth.value = selectMonth.value.add(1,'month')
      selectMonthValue.value = { month:selectMonth.value.month(),year:selectMonth.value.year() }
      monthPickerChange(selectMonth.value)
      await monthlyReload()
      loading.value = false
    }

    const thisMonth = async() => {
      selectMonth.value = dayjs()
      selectMonthValue.value = { month:selectMonth.value.month(),year:selectMonth.value.year() }
      monthPickerChange(selectMonth.value)
      await monthlyReload()
      loading.value = false
    }

    const monthUpdate = async(month:any) => {
      selectMonth.value = selectMonth.value.year(month.year)
      selectMonth.value = selectMonth.value.month(month.month)
      monthPickerChange(selectMonth.value)
      await monthlyReload()
      loading.value = false
    }

    const monthPickerChange = (dayjs:any) => {
        dayjs = dayjs.startOf('month')
        const youbinum = dayjs.day() - 1
        let start = dayjs.subtract(youbinum,'days') //start:カレンダーの最初の月曜日
        if(start.date() == 2) start = start.subtract(1,'week');
        let end = dayjs
        end = end.endOf("month")
        //既にendが最終カレンダーならこの処理は飛ばす
        if(end.add(1,"day").date() != 1){
          const endyoubinum = end.day()
          end = end.add(7 - endyoubinum,"days")
        }
        const weekNumber = Math.ceil(end.diff(start, "days") / 7);
        monthlyDataSource.value = []
        for(let week = 0; week < weekNumber; week++){
          const weekRow = {
            mon: start.toDate(),
            tue: start.add(1,'day').toDate(),
            wed: start.add(2,'day').toDate(),
            thu: start.add(3,'day').toDate(),
            fri: start.add(4,'day').toDate(),
            sat: start.add(5,'day').toDate(),
            sun: start.add(6,'day').toDate(),
            schedules:monthlySchedules,
            week:week,
          }
          monthlyDataSource.value.push(weekRow)
          start = start.add(1,'week')
        }

        const thisMonth = new Date()

        isThisMonth.value = dayjs.month() == thisMonth.getMonth()
    }

    const getStaffName = (code:any) => {
      var tmp = staffColumns.value.filter((e: any) => e.code === code)
      if(tmp.length == 0) return ''
      return tmp[0].title
    }

    const fontWeight = (date:Date) => {
      const today = new Date()
      const style:any = {}
      
      if(date.toDateString() == today.toDateString()){
        style['font-weight'] = 'bold'
      }

      const day = dayjs(date)
      if(day.format('YYYY-MM-DD') in holidays){
        style['color'] = 'red'
      } else  if(date.getDay() == 6){
        style['color'] = 'blue'
      } else if(date.getDay() == 0){
        style['color'] = 'red'
      } 

      return style
    }

    const isToday = (date:Date) => {
      const today = new Date()
      return date.toDateString() == today.toDateString()
    }

    const getWeekNumber = () => {
      const date = selectWeek.value;
      let weekofMonth = Math.floor((date.date() - date.day() + 13) / 7)
      return weekofMonth
    }

    const viweSchedule = (schedule:any) => {
      if( schedule.project == 0 ) {
        if(schedule.achieve) return '半休('+schedule.achieve+'H)'
        return '休み'
      }

      const tmp = AllCodes.value.filter(e=>e.value===schedule.code)
      let label = tmp.length ? tmp[0].label:"Not Select Code"
      let project = getProjectLabel(schedule.project)
      if(project.length>0){
        if(project.indexOf('(') !== 0) project = '('+project
        if(project.lastIndexOf(')')+1 !== project.length) project = project+')'
      }

      if(!schedule.achieve&&!schedule.overtime) return project+label+"(計画:"+schedule.plan+"H)"
      if(schedule.achieve) label += "(実績:"+schedule.achieve+"H)"
      if(schedule.overtime) label += "(残業:"+schedule.overtime+"H)"
      if(schedule.number) label+="(本数:"+schedule.number+"本)"
      return project+label

      //else return project+label+"(実績:"+schedule.achieve+")"
    }

    const viweScheduleCode = (schedule:any) => {
      const tmp = AllCodes.value.filter(e=>e.value===schedule.code)
      const label = tmp.length ? tmp[0].value:"Non"
      return label
      /*

      let project = projects.value.filter(e=>e.value==schedule.project)[0].abbname //getProjectLabel(schedule.project)
      if(project.length>0){
        if(project.indexOf('(') !== 0) project = '('+project
        if(project.lastIndexOf(')')+1 !== project.length) project = project+')'
      }

      if(!schedule.achieve) return project+label+"("+schedule.plan+")"
      else return project+label+"("+schedule.achieve+")"
      */
    }

    const viewTime = (e:any) => {
      return (e.hours.toString().length == 1 ? '0'+ e.hours.toString() : e.hours.toString() ) + ':' + (e.minutes.toString().length == 1 ? '0'+ e.minutes.toString() : e.minutes.toString() )
    }

    const getHolyDayDetail = (date:Date) =>{
      const day = dayjs(date)
      if(day.format('YYYY-MM-DD') in holidays) {
        return holidays[day.format('YYYY-MM-DD')].name
      } else return ""
    }

    const handleOk = (e:any) => {
      modalVisible.value = false;
    };

    const isAchieveRegist = () => {
      if(registAchieve.value.project === 0) return false
      if(registAchieve.value.project == null) return true
      if(registAchieve.value.code == null) return true
      return false
    }

    const editSave = async() => {
      loading.value = true
      const json = registAchieve.value;
      if(json.plan == null) json.plan = 0
      if(json.achieve == null) json.achieve = 0
      if(json.overtime == null) json.overtime = 0
      if(json.number == null) json.number = 0
      try{
        var token = cookies.cookies.get('token');
        let res
        let postmode = false
        if(registAchieve.value.id == 0){
          postmode = true
          res = await axios.post(Constains.tracerbaseURL+'/api/Schedules',JSON.stringify(json),
          {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});
        } else {
          res = await axios.put(Constains.tracerbaseURL+'/api/Schedules/'+registAchieve.value.id,JSON.stringify(json),
          {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});
        }

        if(res.status == 200 || res.status == 201 || res.status == 204){
          if(isPC.value){
            //const put = JSON.parse(JSON.stringify(json))

            registAchieve.value.id = 0
            registAchieve.value.staff = modalStaff.value
            registAchieve.value.date = modalDate.value
            //registAchieve.value.project = null
            registAchieve.value.code = null
            registAchieve.value.action = null
            registAchieve.value.plan = null
            registAchieve.value.achieve = null
            registAchieve.value.number = null
            registAchieve.value.overtime = null
            registAchieve.value.addChange = null
            //await _reload()
            await weeklyReload()
            await monthlyReload()
            loading.value = false

            staffColumns.value[0].fixed=null
            weeklyColumns.value[0].fixed=null
            _scheduleModanView(modalStaff.value,new Date(modalDate.value),_isScheduleModalWeekly.value)
          } else {
            //登録処理
            modalVisible.value = false;
            //await _reload()
            await weeklyReload()
            await monthlyReload()
            loading.value = false
            
            _scheduleModanView(modalStaff.value,new Date(modalDate.value),_isScheduleModalWeekly.value)
          }

        } else {
          //エラー表示
          console.log(res)
          return
        }
      } catch(e){
        console.log(e)
      }
    }

    const deleteAchieve = async(achieve:any) => {
      loading.value = true
      const deleteid = achieve.id
      try{
        var token = cookies.cookies.get('token');
        const res = await axios.delete(Constains.tracerbaseURL+'/api/Schedules/'+achieve.id,
          {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

        if(res.status == 200 || res.status == 201 || res.status == 204){
          //削除処理完了
          if(isPC.value){
            registAchieve.value.id = 0
            registAchieve.value.staff = modalStaff.value
            registAchieve.value.date = modalDate.value
            registAchieve.value.project = null
            registAchieve.value.code = null
            registAchieve.value.action = null
            registAchieve.value.plan = null
            registAchieve.value.achieve = null
            registAchieve.value.number = null
            registAchieve.value.overtime = null
            registAchieve.value.addChange = null
            //await _reload()
            await weeklyReload()
            await monthlyReload()
            loading.value = false

            staffColumns.value[0].fixed=null
            weeklyColumns.value[0].fixed=null
            modalAchieves.value = modalAchieves.value.filter(e=>e.id!=deleteid)
          } else {
            modalVisible.value = false;
          //await _reload()
            await weeklyReload()
            await monthlyReload()
            loading.value = false
          
          _scheduleModanView(modalStaff.value,new Date(modalDate.value),_isScheduleModalWeekly.value)
          }
        } else {
          //エラー表示
          console.log(res)
          return
        }
      } catch(e){
        console.log(e)
      }
    }

    const getProjectLabel= (value:any) => {
      const tmp = allProject.value.filter(e => e.value === value)
      if(tmp.length > 0) return tmp[0].label
      return ""
    }
/*
    const getCodeLabel = (value:any) => {
      const tmp = workCodes.value.filter(e => e.value === value)
      if(tmp.length > 0) return tmp[0].label
      return null
    }
*/
    const filterOption = (input: string, option: any) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) == 0;
    };

    const AutoCompletefilterOption = (input: string, option: any) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const AutoCompleteSelectSchedule = (value:any,option:any) => {
      registSchedule.value.code = option.code
    }

    const AutoCompleteSelectAchieve = (value:any,option:any) => {
      registAchieve.value.code = option.code
    }

    const AchieveInputModalView = (record:any) => {
      AchieveInputVisible.value = true
      AchieveInputLoading.value = false
      AchieveInputData.value = record
    }

    const beforeDay = async() => {
      const day = dayjs(registSchedule.value.date)
      const beforeday = day.subtract(1,'days')
      
      if(_isScheduleModalWeekly.value){
        const start = dayjs(selectWeekList.value[0])
        if(beforeday.isBefore(start)){
          await beforeWeek()
        }

      } else {
        const start = dayjs(monthlyDataSource.value[0].mon)
        if(beforeday.isBefore(start)){
          await beforeMonth()
        }
      }
      _scheduleModanView(registSchedule.value.staff,beforeday.toDate(),_isScheduleModalWeekly.value)

    }

    const afterDay = async() => {
      const day = dayjs(registSchedule.value.date)
      const afterday = day.add(1,'days')
      if(_isScheduleModalWeekly.value){
        const end = dayjs(selectWeekList.value[1])
        if(afterday.isAfter(end)){
          await afterWeek()
        }
      } else {
        const end = dayjs(monthlyDataSource.value[monthlyDataSource.value.length - 1].sun)
        if(afterday.isAfter(end)){
          await afterMonth()
        }
      }
      _scheduleModanView(registSchedule.value.staff,afterday.toDate(),_isScheduleModalWeekly.value)
    }

    const fullVisible = ref(false)
    const setFullVisivle = (visible:boolean) => {
      fullVisible.value = visible
      if(visible == false ){
        staffColumns.value[0].fixed = 'left'
        weeklyColumns.value[0].fixed = 'left'
        
        registAchieve.value.id = 0
        registAchieve.value.staff = modalStaff.value
        registAchieve.value.date = modalDate.value
        registAchieve.value.project = null
        registAchieve.value.code = null
        registAchieve.value.action = null
        registAchieve.value.plan = null
        registAchieve.value.achieve = null
        registAchieve.value.number = null
        registAchieve.value.overtime = null
        registAchieve.value.addChange = null
        
      }
    }

    const inputScheduleVisible = (record:any) => {
      if(record === null || record === undefined){
        //新規作成
        registAchieve.value.id = 0
        registAchieve.value.staff = modalStaff.value
        registAchieve.value.date = modalDate.value
        registAchieve.value.project = null
        registAchieve.value.code = null
        registAchieve.value.action = null
        registAchieve.value.plan = null
        registAchieve.value.achieve = null
        registAchieve.value.number = null
        registAchieve.value.overtime = null
        registAchieve.value.addChange = null
      } else {
        registAchieve.value.id = record.id
        registAchieve.value.staff = record.staff
        registAchieve.value.date = record.date
        registAchieve.value.project = record.project
        registAchieve.value.code = record.code
        registAchieve.value.action = record.action
        registAchieve.value.plan = record.plan
        registAchieve.value.achieve = record.achieve
        registAchieve.value.number = record.number
        registAchieve.value.overtime = record.overtime
        registAchieve.value.addChange = record.addChange

        if(record.project==0){
          restmode.value = record.achieve ? 'part':'full'
        }
      }

      if(!isPC.value) modalVisible.value = true;
    }

    const bushofilterChanged = () =>{
      staffColumnsBushoFilter.value = []
      weeklyDataSourceBushoFilter.value = []
      if(staffViewMode.value){
        if(bushofilter.value.length == 0){
          staffColumnsBushoFilter.value.push(...staffColumns.value)
          weeklyDataSourceBushoFilter.value.push(...weeklyDataSource.value)
        } else {
          staffColumnsBushoFilter.value.push(...staffColumns.value.filter(e=>bushofilter.value.includes(e.busho)||e.dataIndex=='date'))
          weeklyDataSourceBushoFilter.value.push(...weeklyDataSource.value.filter(e=>bushofilter.value.includes(e.staff.busho)))
        }
      } else {
        staffColumnsBushoFilter.value.push(staffColumns.value[0])
        staffColumnsBushoFilter.value.push(staffColumns.value[1])
        weeklyDataSourceBushoFilter.value.push(weeklyDataSource.value[0])
      }
      
    }

    const getOverTime = (staffID:any) => {
      let week = 0;
      
      const staffedittype = getEditType(staffID)

      for(let i = 1; i < weeklyColumns.value.length;i++){
        const date = weeklyColumns.value[i].date.toLocaleDateString('ja-JP')
        const tmp = weeklySchedules.value.filter(e=> e.date==date && e.staff == staffID)
        
        let dayAchieve = 0

        tmp.forEach(schedule => {
          if(staffedittype != 0) {
            if(!isNaN(schedule.achieve)){
              //week += schedule.achieve
              dayAchieve += schedule.achieve
            }
          } else {
            if(!isNaN(schedule.overtime)) {
              week += schedule.overtime
            }
          }
        });

        
        if(staffedittype != 0 && dayAchieve > 8) {
          dayAchieve -= 8
          week += dayAchieve
          //week -= 8
        }
      }

      if(week <= 0) return ''
      return '週の残業:'+week+'時間'
    }

    const holidayRegist = async() =>{
      loading.value = true
      let achieve = 0
      if(restmode.value == 'part') achieve = registAchieve.value.achieve

      registAchieve.value.achieve = null
      const json = {
        id:0,
        project:0,
        action:registAchieve.value.action,
        code:null,
        staff:modalStaff.value,
        date:modalDate.value,
        plan:0,
        achieve:achieve,
        overtime:0,
        addChange:null,
        number:0,
      }

      try{
        var token = cookies.cookies.get('token');
        const res = await axios.post(Constains.tracerbaseURL+'/api/Schedules',JSON.stringify(json),
          {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

        if(res.status == 200 || res.status == 201 || res.status == 204){
          //登録処理
          holidayVisible.value = false;
          //await _reload()
          await weeklyReload()
          await monthlyReload()
          loading.value = false
          
          _scheduleModanView(modalStaff.value,new Date(modalDate.value),_isScheduleModalWeekly.value)
        } else {
          //エラー表示
          console.log(res)
          return
        }
      } catch(e){
        console.log(e)
      }
    }

    const mySchedule:any = []
    const staffSchedule:any = []

    const viewModeChange = async() => {
      if(staffViewMode.value){
        await weeklyReload()
        await _getStaffList()
        loading.value = false
      } else {
        weeklySchedules.value = mySchedule
      }
      
      bushofilterChanged()
    }

    return {
      activeKey: ref('2'),
      monthlyColumns,
      weeklyColumns,
      //staffColumns,
      monthlyDataSource,
      //weeklyDataSource,
      staffDataSource,
      beforeWeek,
      thisWeek,
      afterWeek,
      getStaffName,
      isThisWeek,
      selectWeek,
      selectWeekList,
      selectMonth,
      datePickerChange,
      beforeMonth,
      monthPickerChange,
      afterMonth,
      thisMonth,
      monthUpdate,
      isThisMonth,
      selectMonthValue,
      fontWeight,
      isToday,
      getWeekNumber,
      viweSchedule,
      weekUpdate,
      weekFormat,
      getHolyDayDetail,
      modalVisible,
      modalDate,
      modalStaff,
      modalEditable,
      modalTitle,
      modalAchieves,
      handleOk,
      projects,
      workCodes,
      registSchedule,
      editSave,
      deleteAchieve,
      getProjectLabel,
      //getCodeLabel,
      registAchieve,
      isAchieveRegist,
      filterOption,
      AutoCompletefilterOption,
      options,
      AutoCompleteSelectSchedule,
      AutoCompleteSelectAchieve,
      loading,
      AchieveInputVisible,
      AchieveInputModalView,
      AchieveInputData,
      AchieveInputLoading,
      beforeDay,
      afterDay,
      BedoreDate,
      AfterDate,
      fullVisible,
      setFullVisivle,
      inputScheduleVisible,
      bushofilter,
      bushoSource,
      bushofilterChanged,
      staffColumnsBushoFilter,
      weeklyDataSourceBushoFilter,
      loginUserID,
      viweScheduleCode,
      loginUserAuthority,
      getEditType,
      getOverTime,
      holidayVisible,
      holidayRegist,
      isPC,
      getSumAchieve,
      getSumOvertime,
      staffViewMode,
      viewModeChange,
      restmode,
    };
  },
});
